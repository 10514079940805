import { mapState } from 'vuex';
import { setOptions, bootstrap } from 'vue-gtag';

export default {
  data() {
    return {
      isGtagInitialized: false,
    }
  },
  beforeMount() {
    this.enableGtag();
  },
  methods: {
    enableGtag() {
      if (
        !this.network ||
        !this.network.google_analytics_id ||
        this.isGtagInitialized
      ) {
        return;
      }
      setOptions({
        config: { id: this.network.google_analytics_id }
      })

      bootstrap().then((gtag) => {
        this.isGtagInitialized = true;
      })
    },
  },
  computed: {
    ...mapState({
      network: state => state['booking-networks'].getBookingNetworksRequest.data,
    }),
  }
}
