
  import { mapState } from "vuex";
  import search from "@/mixins/search";
  import gtag from "@/mixins/gtag";

  export default {
    name: "Index",
    layout: "home",
    mixins: [
      search,
      gtag,
    ],
    middleware: [
      'load-page-blocks'
    ],
    beforeMount() {
      //UNCOMMENT IF WE WANT TO REDIRECT TO SINGLE AUTOMATICALLY
      // if (this.isSingleProperty) {
      //   this.$router.push(this.singleRoute(this.network.single_property_id));
      // }
    },
    mounted() {
      // this.getData();
    },
    methods: {
      // async getData() {
      //   await this.$store.dispatch(GET_PROPERTIES_PAGINATION, {
      //     ...this.single_property_id
      //   });
      // },
      handleDropdownActiveChange(isActive, ref) {
        const body = document.body;
        const dropdown = this.$refs[ref].$el;


        if (isActive && this.$device.isMobile) {
          body.setAttribute('id', 'body-dropdown-active');
          dropdown.setAttribute('id', 'dropdown-active');
        } else {
          body.removeAttribute('id');
          dropdown.removeAttribute('id');
        }
      },
      selectiveSearch() {
        if (!this.startDate || !this.endDate) {
          this.startDate = this.$moment().toDate();
          this.endDate = this.$moment().add(1, 'day').toDate();
        }

        if (this.isSingleProperty) {
          this.submitSearch({
            id: this.network.single_property_id,
            redirect: 'single',
            page: 1,
          });

          return;
        }

        this.submitSearch({ redirect: 'properties', page: 1 })
      },
      startDateChanged() {
        this.endDate = this.$moment(this.startDate).add(1, "day").toDate();
        this.$refs.startDate.toggle();
        this.$refs.endDate.toggle();
      },
      endDateChanged() {
        this.$refs.endDate.toggle();
      }
    },
    computed: {
      ...mapState({
        network: state => state['booking-networks'].getBookingNetworksRequest.data,
      }),
      properties() {
        if (!this.network.properties) {
          return [];
        }

        return this.network.properties;
      },
      destinationName() {
        const destination = this.network.destinations.find(i => i.id === this.filters.destination);

        return destination.name || 'aaa';
      }
    },
    head() {
      return {
        meta: [
          {
            hid: 'og:image',
            name: 'og:image',
            content: this.network.images ? this.network.images[0]?.sizes_full_path.high : 'https://source.unsplash.com/random/800%C3%97800/?travel' ,

          },
          { hid: 'description', name: 'description', content: `${this.network.name}: ${this.properties.map(a => a.name).join(', ')}` },
          { hid: 'og:description', name: 'og:description', content: `${this.network.name}: ${this.properties.map(a => a.name).join(', ')}` },
        ]
      }
    }
  };
